import React from 'react';
import DynamicContent from '../../Atendee/DynamicContent.js';
import { Box } from '@mui/material';

function Tutorial({ tutorial }) {
  return (
    <>
      {tutorial && tutorial?.content && (
        <Box marginY={1}>
          <DynamicContent content={tutorial?.content} hideNavbar />
        </Box>
      )}
    </>
  );
}
export default Tutorial;
