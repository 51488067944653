import {
  Box,
  Button,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { useCallback, useState } from 'react';
import { TutorialUIList } from '../../utils/Tutorials/constant';
import FormComponent from '../Atendee/Form/Fields';

function TutorialHeader({ tutorial, setTutorial }) {
  return (
    <Box>
      <Box display={'flex'} flexDirection={'column'} gap={2}>
        {/* Title and Description and published*/}
        <Box display={'flex'} sx={{ alignItems: 'center', gap: 3 }}>
          <Typography variant="subtitle"> Title </Typography>
          <TextField
            variant="standard"
            value={tutorial?.title || ''}
            onChange={(e) =>
              setTutorial({
                ...tutorial,
                title: e.target.value,
              })
            }
            fullWidth
          />
        </Box>
        <Box display={'flex'} sx={{ alignItems: 'center', gap: 2 }}>
          <Typography variant="subtitle"> Description </Typography>
          <Input
            value={tutorial?.description || ''}
            onChange={(e) =>
              setTutorial({
                ...tutorial,
                description: e.target.value,
              })
            }
            fullWidth
            multiline={true}
          />
        </Box>
        <Box
          display={'flex'}
          sx={{ justifyContent: 'space-between', alignItems: 'center' }}
        >
          <Typography variant="subtitle"> Published </Typography>
          <Switch
            checked={tutorial?.published}
            onClick={() => {
              setTutorial((prevTutorial) => ({
                ...prevTutorial,
                published: !prevTutorial.published,
              }));
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}

function AddComponent({ component, setComponent, handleClick }) {
  return (
    <Box display="flex" alignItems="center" sx={{ margin: 3, marginBottom: 2 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">UI</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={component}
          label="Age"
          onChange={(e) => setComponent(e.target.value)}
        >
          {TutorialUIList.map((ui) => (
            <MenuItem key={ui.value} value={ui.value}>
              {ui.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button
        variant="outlined"
        size="small"
        onClick={handleClick}
        disabled={!component}
      >
        Add Component
      </Button>
    </Box>
  );
}

export default function TutorialForm({ tutorial, setTutorial }) {
  const [component, setComponent] = useState('');
  const handleClick = () => {
    setTutorial({
      ...tutorial,
      content: [...tutorial.content, { component }],
    });
    setComponent('');
  };
  const handleChange = useCallback(
    (index, action, newComponent = null) => {
      if (action === 'delete') {
        setTutorial((prevTutorial) => {
          const newContent = prevTutorial.content.filter((_, i) => i !== index);
          return { ...prevTutorial, content: newContent };
        });
      } else {
        setTutorial((prevTutorial) => {
          const newContent = [...prevTutorial.content];
          newContent[index] = newComponent;
          return { ...prevTutorial, content: newContent };
        });
      }
    },
    [setTutorial]
  );

  return (
    <Box>
      <TutorialHeader tutorial={tutorial} setTutorial={setTutorial} />
      <hr />

      {/* Content */}
      <Box>
        {tutorial.content.map((component, index) => (
          <Box key={index}>
            <FormComponent
              index={index}
              uiComponent={component}
              handleChange={handleChange}
            />
          </Box>
        ))}
      </Box>

      {/* Add Component UI */}
      <AddComponent
        component={component}
        setComponent={setComponent}
        handleClick={handleClick}
      />
    </Box>
  );
}
