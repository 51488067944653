export const formatCourseLong = (course) => {
  switch (course) {
    case 'SD1':
      return 'Senior Design 1 (SD1)';
    case 'SD2':
      return 'Senior Design 2 (SD2)';
    default:
      return course;
  }
};

export const formatDepartmentLong = (department) => {
  switch (department) {
    case 'CIS':
      return 'Computer Information Systems (CIS)';
    case 'ECE':
      return 'Electrical and Computer Engineering (ECE)';
    case 'ME':
      return 'Mechanical Engineering (ME)';
    case 'IMSE':
      return 'Industrial and Manufacturing Systems Engineering (IMSE)';
    default:
      return department;
  }
};

export const formatID = (id) => {
  if (!id) return '';
  const idString = String(id);
  const result =
    idString.length > 4
      ? `${idString.slice(0, 4)}-${idString.slice(4)}`
      : idString;
  return result;
};

export const cleanFormatID = (id) => {
  if (!id) return '';
  const idString = String(id);
  const result = idString.replace(/[^0-9]/gi, '');
  return result.replace('-', '');
};

export const academicTitles = [
  'Mr.', // Mister (common courtesy title)
  'Ms.', // Miss/Ms. (common courtesy title for women)
  'Mrs.', // Married woman
  'Dr.', // Doctor (for those with a Ph.D. or medical degree)
  'Prof.', // Professor
  'Asst. Prof.', // Assistant Professor
  'Assoc. Prof.', // Associate Professor
  'TA.', // Teaching Assistant
  'IT.', // Information Technology
  'Engr.', // Engineer
  'Rev.', // Reverend
  'Dean', // Head of a faculty or department
  'Ch.', // Chairperson
  'Sir', // Honorific title (e.g., for knighthood or special recognition)
  'Lady', // Female equivalent of Sir
  'Hon.', // Honorable (for distinguished individuals)
  'Principal', // Head of an institution (common in schools, sometimes in universities)
  'Adj. Prof.', // Adjunct Professor
  'Lect.', // Lecturer
];

export const formatName = (prefix, fullName) => {
  return !!prefix ? `${prefix} ${fullName}` : fullName;
};
