import * as React from 'react';
import Box from '@mui/material/Box';
import MuModal from '@mui/material/Modal';
import Button from '@mui/material/Button';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { lg: 800, md: 600, sm: 'auto' },
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  overflow: 'auto',
  borderRadius: '7px',
  maxHeight: '80%',
  overflowY: 'auto',
};

export default function AdvancedModal({
  open,
  handleClose = () => alert('close'),
  handleSave = () => alert('save'),
  title,
  cta = 'Save',
  children,
  width = { lg: 800, md: 600, sm: 'auto' },
  hideSaveButton = false,
}) {
  return (
    <div>
      <MuModal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ overflow: 'auto' }}
      >
        <Box sx={{ ...style, width: width }}>
          <h2> {title} </h2>

          <hr />

          <Box sx={{ marginY: 1, padding: 1 }}>{children}</Box>

          <hr />

          <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
            {!hideSaveButton && (
              <Button
                onClick={handleSave}
                variant="contained"
                color="primary"
                sx={{ mr: 2 }}
              >
                {cta}
              </Button>
            )}
            <Button onClick={handleClose} variant="contained" color="error">
              close
            </Button>
          </Box>
        </Box>
      </MuModal>
    </div>
  );
}
