import * as React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Dashboard from '../../../../components/Student/Dashboard.js';
import { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Styles } from '../../../../styles/global.js';
import { getTutorial } from '../../../../utils/API.js';

import { Breadcrumbs, Typography } from '@mui/material';

import TutorialEntity from '../../../../components/Entity/SearchTutorial.js';
import Tutorial from '../../../../components/Tutorials/View/index.js';

// Page that shows when a project is already created
const ExistingTutorialDash = () => {
  const [tutorial, setTutorial] = useState(null);
  const { tid } = useParams();
  const navigate = useNavigate();
  const { pid } = useParams();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getTutorial(tid);
        setTutorial(response.data);
      } catch (error) {
        navigate('/');
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [tid, navigate]);

  return (
    <>
      <Box {...Styles.mainBox}>
        <Toolbar />

        {/* Container With Max Width = lg breakpoint */}
        <Container {...Styles.customContainer()}>
          {/*Outer Grid Container */}
          <Grid container spacing={3}>
            {/*Inner Grid Begins*/}

            {/*Inner Grid Item 0: BreadCrumbs */}
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box>
                <Breadcrumbs aria-label="breadcrumb">
                  <Link to={`/student/dashboard/project/${pid}`}>
                    Dashboard
                  </Link>
                  <Link to={`/student/dashboard/project/${pid}/tutorials`}>
                    Tutorials
                  </Link>
                  <Typography color="textPrimary">Tutorial</Typography>
                </Breadcrumbs>
              </Box>
            </Grid>

            {/*Inner Grid Item 1: Page Title */}
            <Grid item lg={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardPageWidget()}>
                Tutorial Dashboard View
              </Paper>
            </Grid>

            {/*Inner Grid Item 2: Tutorial Details */}
            <Grid item lg={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardContentWidget('auto')}>
                <>
                  <TutorialEntity
                    tutorial={tutorial?.tutorial}
                    expandDescription={true}
                  />
                  <Box padding={2}>
                    <Tutorial {...tutorial} />
                  </Box>
                </>
              </Paper>
            </Grid>

            {/*End of Inner Grid*/}
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default function StudentDashboardTutorial() {
  return (
    <Dashboard
      Page={ExistingTutorialDash}
      PageName={'(S) Dashboard - Tutorial'}
    />
  );
}
