import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../utils/AuthContext';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { Alert, Collapse } from '@mui/material';
import AdvancedModal from './AdvancedModal';
import PagesForm from './Atendee/Form';
import { updatePage as savePage } from '../utils/API';
import { useFeedback } from '../utils/FeedbackContext';

function TransitionAlerts({ message, showAlert = false }) {
  const [open, setOpen] = React.useState(true);

  return (
    showAlert && (
      <Box sx={{ width: '100%' }}>
        <Collapse in={open}>
          <Alert
            severity="info"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpen(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ backgroundColor: '#ffdb53' }}
          >
            <Typography
              variant="body1"
              sx={{
                color: '#00274c',
                fontFamily: 'Lato, Arial, sans-serif',
              }}
            >
              {message}
            </Typography>
          </Alert>
        </Collapse>
      </Box>
    )
  );
}

function getColorForRole(role) {
  const colors = {
    ADMIN: '#1F6CFA',
    STUDENT: '#085C25',
    JUDGE: '#673AB7',
    FACULTY: '#1F6CFA',
  };
  return colors[role] || 'red'; // Default color if the role is not found
}

const pages = [
  {
    url: '/',
    title: 'Home',
  },
  {
    url: '/participant-info',
    title: 'Participant Info: General',
  },
  {
    url: '/participant-info/senior-design-1',
    title: 'Participant Info: Senior Design 1',
  },
  {
    url: '/participant-info/senior-design-2',
    title: 'Participant Info: Senior Design 2',
  },
  {
    url: '/event-attendee-info',
    title: 'Event Attendee Info',
  },
  {
    url: '/previous-years',
    title: 'Previous Years',
  },
  {
    url: '/portal/student',
    title: 'Student Portal',
  },
  {
    url: '/portal/faculty',
    title: 'Faculty Portal',
  },
  {
    url: '/portal/judge',
    title: 'Judge Portal',
  },
  {
    url: '/contact',
    title: 'Contact',
  },
];

function ParticipantInfoMenu({ scrolling }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const buttonOptions = {
    my: 2,
    color: 'white',
    display: 'block',
    fontSize: '17px',
    textTransform: 'none',
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onMouseEnter={handleOpen}
        sx={{
          ...buttonOptions,
          display: 'flex',
          justifyContent: 'space-between', // Align text and icon
          alignItems: 'center', // Center vertically
        }}
        endIcon={<ExpandMoreIcon />}
      >
        Participant Info
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        disableScrollLock={true}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
          onMouseLeave: handleClose,
          color: 'white',
        }}
        PaperProps={{
          sx: {
            backgroundColor: '#212121',
            transition: 'background-color 0.2s',
            transitionDelay: '0.1s',
            border: 1,
            paddingX: 2,
          },
        }}
      >
        <Link to="/participant-info" style={{ textDecoration: 'none' }}>
          <MenuItem
            sx={{
              color: 'white',
            }}
            onClick={handleClose}
          >
            General Info
          </MenuItem>
        </Link>
        <Link
          to="/participant-info/senior-design-1"
          style={{ textDecoration: 'none' }}
        >
          <MenuItem
            sx={{
              color: 'white',
            }}
            onClick={handleClose}
          >
            Senior Design 1
          </MenuItem>
        </Link>
        <Link
          to="/participant-info/senior-design-2"
          style={{ textDecoration: 'none' }}
        >
          <MenuItem
            sx={{
              color: 'white',
            }}
            onClick={handleClose}
          >
            Senior Design 2
          </MenuItem>
        </Link>
      </Menu>
    </div>
  );
}

function PortalsMenu({ scrolling }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const buttonOptions = {
    my: 2,
    color: 'white',
    display: 'block',
    fontSize: '17px',
    textTransform: 'none',
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu2' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onMouseEnter={handleOpen}
        sx={{
          ...buttonOptions,
          display: 'flex',
          justifyContent: 'space-between', // Align text and icon
          alignItems: 'center', // Center vertically
        }}
        endIcon={<ExpandMoreIcon />}
      >
        Portals
      </Button>
      <Menu
        id="basic-menu2"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        disableScrollLock={true}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
          onMouseLeave: handleClose,
        }}
        PaperProps={{
          sx: {
            backgroundColor: '#212121',
            transition: 'background-color 0.2s',
            transitionDelay: '0.1s',
            border: 1,
            paddingX: 2,
          },
        }}
      >
        <Link
          to="/portal/student"
          style={{ textDecoration: 'none', color: 'white' }}
        >
          <MenuItem onClick={handleClose}>Student</MenuItem>
        </Link>

        <Link
          to="/portal/faculty"
          style={{ textDecoration: 'none', color: 'white' }}
        >
          <MenuItem onClick={handleClose}>Faculty</MenuItem>
        </Link>

        <Link
          to="/portal/judge"
          style={{ textDecoration: 'none', color: 'white' }}
        >
          <MenuItem onClick={handleClose}>Judge</MenuItem>
        </Link>
      </Menu>
    </div>
  );
}

const MyLinks = ({ handleCloseNavMenu, scrolling }) => {
  const buttonOptions = {
    my: 2,
    color: 'white',
    display: 'block',
    fontSize: '17px',
    textTransform: 'none',
  };
  return (
    <>
      <Link to="/" style={{ textDecoration: 'none' }}>
        <Button onClick={handleCloseNavMenu} sx={buttonOptions}>
          {'Home'}
        </Button>
      </Link>

      <ParticipantInfoMenu scrolling={scrolling} />

      <PortalsMenu scrolling={scrolling} />

      <Link to="/event-attendee-info" style={{ textDecoration: 'none' }}>
        <Button onClick={handleCloseNavMenu} sx={buttonOptions}>
          {'Event Atendee Info'}
        </Button>
      </Link>
      <Link to="/previous-years" style={{ textDecoration: 'none' }}>
        <Button onClick={handleCloseNavMenu} sx={buttonOptions}>
          {'Previous Years'}
        </Button>
      </Link>
      <Link to="/contact" style={{ textDecoration: 'none' }}>
        <Button onClick={handleCloseNavMenu} sx={buttonOptions}>
          {'Contact'}
        </Button>
      </Link>
    </>
  );
};

function ResponsiveAppBar({
  options,
  content,
  setContent,
  edit = false,
  infoBanner = null,
  page,
}) {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [scrolling, setScrolling] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState('');
  const { user, logout, isLoggedIn } = useAuth();
  const navigate = useNavigate();
  const { notifyError, notifySuccess } = useFeedback();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = (event) => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = (event) => {
    setAnchorElUser(null);
  };

  const settings = [
    {
      title: 'Dashboard',
      feature: function () {
        if (user?.role === 'FACULTY') {
          navigate(
            `/faculty/dashboard?department=${user?.department}&year=${user?.year}&course=SD2`
          );
          return;
        }
        if (user?.role === 'ADMIN') {
          navigate(`/admin/dashboard?year=${new Date().getFullYear()}`);
          return;
        }
        if (user.role === 'STUDENT') {
          if (user.projectsOwned.length === 0) {
            navigate('/student/dashboard/project/0');
            return;
          }
          navigate(`/student/dashboard/project/${user.projectsOwned[0]._id}`);
          return;
        }
        if (user?.role === 'JUDGE') {
          navigate(`/judge/dashboard`);
          return;
        }
      },
    },
    {
      title: 'Logout',
      feature: function () {
        return logout();
      },
    },
  ];

  useEffect(() => {
    const handleScroll = () => {
      const isTop = window.scrollY < 100;
      if (!isTop && !scrolling) {
        setScrolling(true);
      } else if (isTop && scrolling) {
        setScrolling(false);
      }
    };

    document.addEventListener('scroll', handleScroll);

    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, [scrolling]);

  useEffect(() => {
    setShowAlert(infoBanner?.showAlert || false);
    setMessage(infoBanner?.message || '');
  }, []);

  async function handleSaveContent() {
    try {
      await savePage(page, { content: content });
      notifySuccess('Content saved successfully');
      setShowModal(false);
    } catch (error) {
      console.error(error);
      notifyError(error?.response?.data?.errMessage || 'Error saving content');
    }
  }

  return (
    <>
      <TransitionAlerts message={message} showAlert={showAlert} />
      <AppBar
        position="sticky"
        sx={{
          backgroundColor: scrolling ? '#212121' : '#0F284B',
          transition: 'background-color 0.2s',
          transitionDelay: '0.1s',
        }}
        // sx={{
        //   backgroundImage: 'url("/images/CECS_new.png")', // Ensure the path is within url()
        //   backgroundSize: "cover", // Cover the entire AppBar
        //   backgroundRepeat: "no-repeat", // Prevent image from repeating
        //   backgroundPosition: "center", // Center the background image
        //   height: "600px",
        // }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box
              component="img"
              src="/images/logo192.png"
              alt="Custom Icon"
              sx={{
                display: { xs: 'none', md: 'flex' },
                width: 40,
                marginRight: 1,
              }}
            />
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontFamily: 'monospace',
                fontWeight: 100,
                letterSpacing: '.01rem',
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              CECS Senior Design Day
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                MenuListProps={{ disablePadding: true }}
                disableScrollLock={true}
                sx={{ mt: '45px' }}
              >
                {pages.map((page) => (
                  <Link
                    key={page.url}
                    to={page.url}
                    style={{ textDecoration: 'none', color: 'black' }}
                  >
                    <MenuItem key={page} onClick={handleCloseNavMenu}>
                      {page.title}
                    </MenuItem>
                  </Link>
                ))}
              </Menu>
            </Box>
            <Box
              component="img"
              src="/images/logo192.png"
              alt="Custom Icon"
              sx={{
                display: { xs: 'flex', md: 'none' },
                width: 40,
                mr: 1,
              }}
            />
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="#app-bar-with-responsive-menu"
              sx={{
                mr: 2,
                display: { xs: 'flex', md: 'none' },
                flexGrow: 1,
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              CECS Senior Design Day
            </Typography>
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: 'none', md: 'flex' },
                justifyContent: 'end',
                mr: 5,
              }}
            >
              <MyLinks
                handleCloseNavMenu={handleCloseNavMenu}
                scrolling={scrolling}
              />
            </Box>

            {edit && isLoggedIn && user?.role === 'ADMIN' && (
              <>
                <Button
                  variant="contained"
                  sx={{ mx: 1 }}
                  onClick={() => setShowModal(true)}
                >
                  Edit
                </Button>
                <AdvancedModal
                  title="Edit Page"
                  width="80%"
                  open={showModal}
                  handleClose={() => {
                    setShowModal(false);
                  }}
                  handleSave={handleSaveContent}
                >
                  <PagesForm content={content} setContent={setContent} />
                </AdvancedModal>
              </>
            )}

            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title={isLoggedIn && 'Open settings'}>
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  {isLoggedIn ? (
                    <Avatar
                      sx={{
                        bgcolor: getColorForRole(user.role),
                        fontSize: '18px',
                      }}
                    >
                      {(user.firstName[0] + user.lastName[0]).toUpperCase()}{' '}
                    </Avatar>
                  ) : (
                    <Avatar src="/broken-image.jpg" />
                  )}
                </IconButton>
              </Tooltip>

              {isLoggedIn && (
                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbars"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  MenuListProps={{ disablePadding: true }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                  disableScrollLock={true}
                >
                  {settings.map((setting) => (
                    <MenuItem
                      key={setting.title}
                      onClick={handleCloseUserMenu && setting.feature}
                    >
                      {setting.title}
                    </MenuItem>
                  ))}
                </Menu>
              )}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
}
export default ResponsiveAppBar;
