import { Box } from '@mui/material';
import { useState } from 'react';
import UserProfile from './UserProfile';

export const UserLink = ({ user, children }) => {
  const [open, setOpen] = useState(false);
  return (
    <Box>
      <Box onClick={() => setOpen(true)} sx={{ cursor: 'pointer' }}>
        <Box sx={{ '&:hover': { textDecoration: 'underline' } }}>
          {children}
        </Box>
      </Box>
      <UserProfile open={open} onClose={() => setOpen(false)} user={user} />
    </Box>
  );
};
export default UserLink;
