import { Box, Divider, Typography } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import { StylesForm } from '../../../styles/global';

// Widget for Abstract
const InformProjectAbstract = ({ project, dueDate, showLink = false }) => {
  const { pid } = useParams();
  return (
    <>
      {project && (
        <>
          {/* Project Title + Due Date */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            {/* Project Title */}
            <Typography {...StylesForm.text('h6')}>
              {project.data.title.length > 50
                ? `${project.data.title.substring(0, 50)}... Abstract`
                : `${project.data.title} Abstract`}
            </Typography>
            <Divider />

            {/* Due Date */}
            <Typography
              {...StylesForm.text('p')}
              sx={{
                marginBottom: 1,
                color: '#226E93',
              }}
            >
              (due {dueDate || 'unassigned'} )
            </Typography>
          </Box>

          <Divider />

          {/* Abstract */}

          <Typography
            {...StylesForm.text('p')}
            sx={{
              marginBottom: 1,
              color: 'darkslategray',
              whiteSpace: 'pre-line',
            }}
          >
            {!project.data.abstract
              ? 'No abstract submitted.'
              : project.data.abstract}
          </Typography>

          {/* Link to add abstract */}
          {showLink && (
            <Box display={'flex'} alignItems={'end'} height={'100%'}>
              <Link
                to={`/student/dashboard/project/${pid}/abstract`}
                style={{ color: '#1976D2', margin: 3 }}
              >
                {' '}
                To submit the abstact, click here{' '}
              </Link>
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default InformProjectAbstract;
