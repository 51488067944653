import * as React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Dashboard from '../../../../components/Faculty/Dashboard.js';
import { useState, useEffect } from 'react';
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { Styles } from '../../../../styles/global.js';
import {
  deleteTutorial,
  getTutorial,
  updateTutorial,
} from '../../../../utils/API.js';

import { Alert, Breadcrumbs, Button, Typography } from '@mui/material';

import TutorialEntity from '../../../../components/Entity/SearchTutorial.js';
import Tutorial from '../../../../components/Tutorials/View/index.js';
import AdvancedModal from '../../../../components/AdvancedModal.js';
import TutorialForm from '../../../../components/Tutorials/index.js';
import useFeedback from '../../../../utils/FeedbackContext/useFeedback.js';

function Actions({ tutorial, setTutorial }) {
  const [deleteModalShowing, setDeleteModalShowing] = useState(false);
  const [editModalShowing, setEditModalShowing] = useState(false);
  const [error, setError] = useState();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { notifyError, notifySuccess } = useFeedback();
  const handleEdit = async () => {
    try {
      await updateTutorial(tutorial?._id, { content: tutorial?.content || [] });
      await updateTutorial(tutorial?._id, {
        published: tutorial?.published || false,
      });
      notifySuccess('Tutorial edited successfully');
      setError(null);
      setEditModalShowing(false);
    } catch (error) {
      console.error('Error Editing Tutorial:', error);
      setError('Error while Editing tutorial');
      notifyError(
        'Error while Editing tutorial, check error message for more details'
      );
    }
  };

  const handleDeletion = async () => {
    try {
      await deleteTutorial(tutorial?._id);
      notifySuccess('Tutorial deleted successfully');
      navigate(`/faculty/dashboard/tutorials?${searchParams.toString()}`);
      setDeleteModalShowing(false);
    } catch (error) {
      console.error('Error deleting tutorial:', error);
      notifyError('Error deleting tutorial');
    }
  };
  return (
    <>
      {tutorial && (
        <Box display="flex" justifyContent={'end'}>
          <Box>
            <Button color="primary" onClick={() => setEditModalShowing(true)}>
              Edit
            </Button>
            <Button color="error" onClick={() => setDeleteModalShowing(true)}>
              Delete
            </Button>
          </Box>
          <AdvancedModal
            open={deleteModalShowing}
            title="Delete Tutorial"
            handleClose={() => setDeleteModalShowing(false)}
            handleSave={handleDeletion}
          >
            <Alert severity="error">
              Are you sure you want to delete this tutorial? Click Save to
              confirm.
            </Alert>
          </AdvancedModal>
          <AdvancedModal
            open={editModalShowing}
            title="Edit a Tutorial"
            handleClose={() => setEditModalShowing(false)}
            handleSave={handleEdit}
          >
            <TutorialForm tutorial={tutorial} setTutorial={setTutorial} />
            {error && (
              <Alert severity="error" sx={{ marginY: 1 }}>
                {error}
              </Alert>
            )}
          </AdvancedModal>
        </Box>
      )}
    </>
  );
}

// Page that shows when a project is already created
const ExistingTutorialDash = () => {
  const [tutorial, setTutorial] = useState(null);
  const { tid } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getTutorial(tid);
        setTutorial(response.data.tutorial);
      } catch (error) {
        navigate('/');
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [tid, navigate]);

  return (
    <>
      <Box {...Styles.mainBox}>
        <Toolbar />

        {/* Container With Max Width = lg breakpoint */}
        <Container {...Styles.customContainer()}>
          {/*Outer Grid Container */}
          <Grid container spacing={3}>
            {/*Inner Grid Begins*/}

            {/*Inner Grid Item -1: Actions */}
            <Grid item lg={12} sm={12} xs={12}>
              <Actions tutorial={tutorial} setTutorial={setTutorial} />
            </Grid>

            {/*Inner Grid Item 0: BreadCrumbs */}
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box>
                <Breadcrumbs aria-label="breadcrumb">
                  <Link
                    to={`/faculty/dashboard?year=${new Date().getFullYear()}`}
                  >
                    Dashboard
                  </Link>
                  <Link
                    to={`/faculty/dashboard/tutorials?${searchParams.toString()}`}
                  >
                    Tutorials
                  </Link>
                  <Typography color="textPrimary">Tutorial</Typography>
                </Breadcrumbs>
              </Box>
            </Grid>

            {/*Inner Grid Item 1: Page Title */}
            <Grid item lg={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardPageWidget()}>
                Tutorial Dashboard View
              </Paper>
            </Grid>

            {/*Inner Grid Item 2: Tutorial Details */}
            <Grid item lg={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardContentWidget('auto')}>
                <>
                  <TutorialEntity
                    tutorial={tutorial}
                    expandDescription={true}
                  />
                  <Box padding={2}>
                    <Tutorial tutorial={tutorial} />
                  </Box>
                </>
              </Paper>
            </Grid>

            {/*End of Inner Grid*/}
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default function FacultyDashboardTutorial() {
  return (
    <Dashboard
      Page={ExistingTutorialDash}
      PageName={'(F) Dashboard - Tutorial'}
    />
  );
}
