import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useState } from 'react';
import { useAuth } from '../../utils/AuthContext.js';
import { useNavigate, Link } from 'react-router-dom';
import NavBar from '../../components/AttendeeNavBar.js';
import { forgotPassword } from '../../utils/API.js';
import useFeedback from '../../utils/FeedbackContext/useFeedback.js';

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {'Copyright © '}
      <Link color="inherit" to="/student/login">
        University of Michigan-Dearborn
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function Forgot() {
  const [email, setEmail] = useState('');
  const { notifyError, notifySuccess } = useFeedback();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await forgotPassword(email);
      notifySuccess(response?.data?.message || 'Password reset email sent');
    } catch (error) {
      notifyError(
        error?.response?.data?.errMessage ||
          'An error occurred. Please try again later.'
      );
    }
  };

  const invalidInput = () => {
    if (email) {
      return false;
    }

    return true;
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <NavBar />
      <Container component="main" maxWidth="xs">
        <CssBaseline />

        <Box
          sx={{
            marginTop: 11,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Reset Password
          </Typography>
          <Alert severity="info" sx={{ my: 3 }}>
            Enter your email address and we will send you a link to reset your
            password.
          </Alert>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              autoFocus
              placeholder="Your account email"
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={!!invalidInput()}
            >
              Reset
            </Button>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}
