import React, { useState } from 'react';
import FeedbackContext from './FeedbackContext';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';

const FeedbackProvider = ({ children }) => {
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = useState('');
  const [severity, setSeverity] = useState('success');

  function TransitionLeft(props) {
    return <Slide {...props} direction="left" />;
  }

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setValue('');
    setOpen(false);
  };

  const notifyError = (message) => {
    setOpen(false);
    setTimeout(() => {
      setValue(message);
      setSeverity('error');
      handleClick();
    }, 200);
  };

  const notifySuccess = (message) => {
    setOpen(false);
    setTimeout(() => {
      setValue(message);
      setSeverity('success');
      handleClick();
    }, 200);
  };

  return (
    <FeedbackContext.Provider value={{ notifyError, notifySuccess }}>
      {open && (
        <Snackbar
          open={open}
          autoHideDuration={8000}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          TransitionComponent={TransitionLeft}
          TransitionProps={{ timeout: 500 }}
        >
          <Alert
            onClose={handleClose}
            severity={severity}
            variant="filled"
            sx={{ width: '100%', maxWidth: '500px' }}
          >
            {value}
          </Alert>
        </Snackbar>
      )}
      {children}
    </FeedbackContext.Provider>
  );
};

export default FeedbackProvider;
