import { Box, Container, Grid, Paper, Toolbar } from '@mui/material';
import Dashboard from '../../../components/Faculty/Dashboard';
import { Styles } from '../../../styles/global';
import { useEffect, useMemo, useState } from 'react';
import { getUsers, objectToQueryString } from '../../../utils/API';
import { useSearchParams } from 'react-router-dom';
import UsersTable from '../../../components/UsersTable';

function Students() {
  const [students, setStudents] = useState([]);
  const [searchParams] = useSearchParams();
  useEffect(() => {
    const fetchStudents = async () => {
      const params = {};
      if (searchParams.get('department')) {
        params.department = searchParams.get('department');
      }
      if (searchParams.get('year')) {
        params.year = searchParams.get('year');
      }

      if (searchParams.get('course')) {
        params.course = searchParams.get('course');
      }

      const response = await getUsers(objectToQueryString(params));
      setStudents(response?.data?.data || []);
    };
    try {
      fetchStudents();
    } catch (error) {
      console.log(error);
    }
  }, [searchParams.toString()]);

  return (
    <>
      <Box {...Styles.mainBox}>
        <Toolbar />

        {/* Container With Max Width = lg breakpoint */}
        <Container {...Styles.customContainer()}>
          {/*Outer Grid Container */}
          <Grid container spacing={3}>
            {/*Inner Grid Begins*/}

            {/*Inner Grid Item 1: Page Title */}
            <Grid item lg={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardPageWidget()}>Students Users</Paper>
            </Grid>

            {/*Inner Grid Item 1: Page Title */}
            <Grid item lg={12} sm={12} xs={12}>
              <Box {...Styles.dashboardContentWidget('auto')}>
                <UsersTable rows={students} />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}

export default function FacultyStudent() {
  return <Dashboard Page={Students} PageName={'(F) Dashboard - Students'} />;
}
