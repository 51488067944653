import { useEffect, useState } from 'react';
import { Box, Divider, Pagination, Stack } from '@mui/material';
import { SearchProject, SearchRubric, SearchTutorial } from '../Entity';
import { Link, useSearchParams } from 'react-router-dom';
const ItemsListed = ({ items, link, itemType }) => {
  const [searchParams] = useSearchParams();

  const SearchComponent = ({ item }) => {
    switch (itemType) {
      case 'projects':
        return <SearchProject project={item} />;
      case 'tutorials':
        return <SearchTutorial tutorial={item} />;
      case 'rubrics':
        return <SearchRubric rubric={item} />;
      default:
        return <></>;
    }
  };

  return items.data.map((item) => {
    return (
      <Link
        key={item?._id}
        to={`${link}/${item._id}/?${searchParams.toString()}`}
        style={{ textDecoration: 'none', color: 'inherit' }}
      >
        <SearchComponent key={item?._id} item={item} />
      </Link>
    );
  });
};

const PaginationComponent = ({ count, page, handlePageChange }) => {
  return (
    <Box padding={2}>
      <Stack spacing={2}>
        <Pagination
          count={count}
          color="primary"
          page={page}
          onChange={handlePageChange}
        />
      </Stack>
    </Box>
  );
};

const View = ({ topRef, items, link, itemType }) => {
  const [page, setPage] = useState(1);
  const [searchParams, setSearchParams] = useSearchParams();

  const handlePageChange = (event, value) => {
    setPage(value);
    const params = new URLSearchParams(searchParams);
    params.set('page', value);
    setSearchParams(params.toString());
  };

  useEffect(() => {
    // Scroll to the top when page changes
    if (topRef.current) {
      topRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [page]); // Trigger effect when page changes

  return items?.data?.length ? (
    <>
      {/* Ref element to scroll to */}
      <Box sx={{ width: '100%' }}>
        <ItemsListed items={items} link={link} itemType={itemType} />
      </Box>
      <Box sx={{ width: '100%' }}>
        <Divider />
        <Box display="flex" justifyContent={'center'}>
          <PaginationComponent
            count={items.totalPages}
            page={page}
            handlePageChange={handlePageChange}
          />
        </Box>
      </Box>
    </>
  ) : (
    <Box justifyContent="left" alignItems="center">
      <Box>No {itemType} are available right now, please check back later.</Box>
    </Box>
  );
};

export default View;
