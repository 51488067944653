import {
  Autocomplete,
  Box,
  Button,
  Divider,
  TextField,
  Typography,
} from '@mui/material';
import { StylesForm } from '../../../styles/global';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  getStudentsOptions,
  inviteStudent,
  removeStudent,
} from '../../../utils/API';
import { useFeedback } from '../../../utils/FeedbackContext';
import UserLink from '../../UserLink';

// Widget for Managing Members
const ManageMembers = ({ project, flag, setFlag, showLink = false }) => {
  const { pid } = useParams();
  const [invite, setInvite] = useState('');
  const [students, setStudents] = useState([]);
  const [confirm, setConfirm] = useState(
    new Array(project?.data.owners.length).fill(false) || []
  );
  const { notifyError, notifySuccess } = useFeedback();

  const handleRemoval = async (uid) => {
    try {
      await removeStudent(pid, uid);
      notifySuccess('Student removed successfully');
      setFlag(!flag);
    } catch (error) {
      console.error('Error fetching data:', error);
      notifyError(error?.response?.data?.errMessage || 'An error occurred');
    }
  };

  const handleAddition = async () => {
    try {
      await inviteStudent(pid, invite);
      notifySuccess('Student added successfully');
      setFlag(!flag);
      setInvite('');
      setConfirm(new Array(project?.data.owners.length).fill(false));
    } catch (error) {
      console.error('Error fetching data:', error);
      notifyError(error?.response?.data?.errMessage || 'An error occurred');
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!project) {
        return;
      }
      try {
        const response = await getStudentsOptions(
          {
            department: project?.data.department,
            year: project?.data.year,
            course: project?.data.course,
          },
          true
        );
        setStudents(response?.data?.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [pid, project, flag]);

  return (
    <>
      {project && (
        <>
          {/* Project Title + Number of Members */}
          <Box
            display={'flex'}
            justifyContent={'space-between'}
            flexDirection={'column'}
          >
            <Box height="80%">
              <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                justifyContent={'left'}
              >
                {/* Project Title*/}
                <Typography {...StylesForm.text('h6')}>
                  {project.data.title.length > 25
                    ? `${project.data.title.substring(0, 25)}... Members`
                    : `${project.data.title} Members`}
                </Typography>

                {/* Number of Members */}
                <Typography
                  {...StylesForm.text('p')}
                  sx={{
                    marginBottom: 1,
                    color: '#226E93',
                  }}
                >
                  ({`${project.data.owners.length} Members`})
                </Typography>
              </Box>

              <Divider />

              {/* Lists Project Members: Names + Email */}
              <Box display="flex" flexDirection={'column'} overflow={'auto'}>
                {project.data.ownersDescription.map((owner, index) => (
                  <Box
                    key={owner?._id}
                    marginY={0.5}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    display={'flex'}
                  >
                    <Typography
                      {...StylesForm.text('p')}
                      key={owner._id}
                      sx={{
                        marginBottom: 1,
                        color: 'darkslategray',
                      }}
                    >
                      <UserLink user={owner}>
                        ({owner.fullName}) - {owner.email}
                      </UserLink>
                    </Typography>
                    {!confirm[index] && (
                      <Button
                        variant="outlined"
                        color="info"
                        onClick={(prev) =>
                          setConfirm((prev) => {
                            const temp = new Array(
                              project?.data.owners.length
                            ).fill(false);
                            temp[index] = true;
                            return [...temp];
                          })
                        }
                      >
                        Remove
                      </Button>
                    )}
                    {confirm[index] && (
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => handleRemoval(owner?._id)}
                      >
                        Confirm Remove
                      </Button>
                    )}
                  </Box>
                ))}
              </Box>
              <Divider sx={{ marginBottom: 1.5 }} />
            </Box>

            {/* Link to add members */}
            <Box display={'flex'} alignItems={'end'} height={'20%'}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={students}
                sx={{ width: '80%' }}
                value={invite}
                renderInput={(params) => (
                  <TextField {...params} label="Students from the same class" />
                )}
                onChange={(event, value) => {
                  if (value) {
                    setInvite(value.email);
                  }
                }}
              />
              <Button
                variant="outlined"
                sx={{ width: '20%', height: '55px' }}
                onClick={handleAddition}
              >
                Add
              </Button>
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default ManageMembers;
