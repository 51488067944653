import { Alert, Box, Button, Divider, Typography } from '@mui/material';
import { StylesForm } from '../../../styles/global';
import { useAuth } from '../../../utils/AuthContext';
import { addBidToProject, removeBidFromProject } from '../../../utils/API';
import { useFeedback } from '../../../utils/FeedbackContext';

// Widget for Project Details
const ProjectBid = ({ project, flag, setFlag }) => {
  const { user } = useAuth();
  const { notifyError, notifySuccess } = useFeedback();

  async function handleBid() {
    try {
      await addBidToProject(project._id);
      notifySuccess('Bid added to project');
      setFlag(!flag);
    } catch (error) {
      console.log(error);
      notifyError('Error adding bid to project');
    }
  }

  async function handleRemoveBid() {
    try {
      await removeBidFromProject(project._id);
      notifySuccess('Bid removed from project');
      setFlag(!flag);
    } catch (error) {
      console.log(error);
      notifyError('Error removing bid from project');
    }
  }

  return (
    <>
      {project && (
        <>
          {/* Title: Project Bid */}
          <Typography {...StylesForm.text('h6')}>Project Bid</Typography>

          <Divider sx={{ marginBottom: 2 }} />

          {/* Bid On Project */}
          {!project?.projectBids?.includes(user?._id) && (
            <>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleBid()}
              >
                {' '}
                Bid on Project{' '}
              </Button>
              <Alert severity="info">
                You can bid on this project by clicking the button above. This
                will notify the administrators that you are interested in
                judging this project.
              </Alert>
            </>
          )}

          {/* Already Bid */}
          {/* Bid On Project */}
          {project?.projectBids?.includes(user?._id) && (
            <>
              <Button
                variant="contained"
                color="error"
                onClick={() => handleRemoveBid()}
              >
                {' '}
                Remove Bid from Project{' '}
              </Button>
              <Alert severity="info">
                You have already bid on this project. You can remove your bid by
                clicking the button above.
              </Alert>
            </>
          )}

          {/* End of Info */}

          <Divider />
        </>
      )}
    </>
  );
};

export default ProjectBid;
