import { Divider, Typography } from '@mui/material';
import { StylesForm } from '../../../styles/global';
import {
  formatCourseLong,
  formatDepartmentLong,
} from '../../../utils/formatAttributes';

const SlateBaseText = ({ children }) => {
  return (
    <>
      <Typography {...StylesForm.text('p')} color="darkslategray">
        {children}
      </Typography>
    </>
  );
};

// Widget for Project Details
const InformProjectDetails = ({ project }) => {
  return (
    <>
      {project && (
        <>
          {/* Title: Project Details */}
          <Typography {...StylesForm.text('h6')}>Project Details</Typography>

          <Divider />

          {/* Start of Info: Title, Course, Department, Year, Clients, Instructor */}
          <>
            <Typography {...StylesForm.text('p')}>
              Project Title:{' '}
              <SlateBaseText>
                <strong>{`${project.data.title}`}</strong>
              </SlateBaseText>
            </Typography>

            <Typography {...StylesForm.text('p')}>
              Course Equivalent:{' '}
              <SlateBaseText>{`${formatCourseLong(
                project.data.course
              )}`}</SlateBaseText>
            </Typography>

            <Typography {...StylesForm.text('p')}>
              Department:{' '}
              <SlateBaseText>{`${formatDepartmentLong(
                project.data.department
              )}`}</SlateBaseText>
            </Typography>

            <Typography {...StylesForm.text('p')}>
              Competition Participant Year:{' '}
              <SlateBaseText>{`${project.data.year}`}</SlateBaseText>
            </Typography>

            <Typography {...StylesForm.text('p')}>
              Client(s):{' '}
              <SlateBaseText>{`${project.data.clients}`}</SlateBaseText>
            </Typography>

            <Typography {...StylesForm.text('p')}>
              Instructor(s):{' '}
              <SlateBaseText>{`${project.data.instructor}`}</SlateBaseText>
            </Typography>
          </>
          {/* End of Info */}

          <Divider />
        </>
      )}
    </>
  );
};

export default InformProjectDetails;
