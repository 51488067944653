export const AtendeeUIList = [
  { value: 'YouTube', label: 'YouTube' },
  { value: 'Image', label: 'Image' },
  { value: 'Dynamic', label: 'DynamicText' },
  { value: 'AccordionImage', label: 'AccordionImage' },
  { value: 'Footer', label: 'Footer' },
  { value: 'TextBanner', label: 'TextBanner' },
  { value: 'TwoButtonLink', label: 'TwoButtonLink' },
  { value: 'AccordionDynamic', label: 'AccordionDynamic' },
];
