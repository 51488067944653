import { Box, Divider, Typography } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import { StylesForm } from '../../../styles/global';
import { useAuth } from '../../../utils/AuthContext';
import UserLink from '../../UserLink';

// Widget for Members
const InformProjectMembers = ({ project, showLink = false }) => {
  const { pid } = useParams();

  const { user } = useAuth();

  return (
    <>
      {project && (
        <>
          {/* Project Title + Number of Members */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            {/* Project Title*/}
            <Typography {...StylesForm.text('h6')}>
              {project.data.title.length > 25
                ? `${project.data.title.substring(0, 25)}... Members`
                : `${project.data.title} Members`}
            </Typography>

            {/* Number of Members */}
            <Typography
              {...StylesForm.text('p')}
              sx={{
                marginBottom: 1,
                color: '#226E93',
              }}
            >
              ({`${project.data.owners.length} Members`})
            </Typography>
          </Box>

          <Divider />

          {/* Lists Project Members: Names + Email */}
          {project.data.ownersDescription.map((owner) => (
            <Typography
              {...StylesForm.text('p')}
              key={owner._id}
              sx={{
                marginBottom: 1,
                color: 'darkslategray',
              }}
            >
              {user.role !== 'STUDENT' ? (
                <UserLink user={owner}>
                  ({owner.fullName}) - {owner.email}
                </UserLink>
              ) : (
                `(${owner.fullName}) - ${owner.email}`
              )}
            </Typography>
          ))}

          {/* Link to add members */}
          {showLink && (
            <Box display={'flex'} alignItems={'end'} height={'100%'}>
              <Link
                to={`/student/dashboard/project/${pid}/invites`}
                style={{ color: '#1976D2', margin: 3 }}
              >
                {' '}
                To add members to the project, click here.{' '}
              </Link>
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default InformProjectMembers;
