import { Check, QuestionAnswer } from '@mui/icons-material';
import { Box, Chip, Divider, Grid, Typography } from '@mui/material';
import { useAuth } from '../../utils/AuthContext';

export default function SearchProject({ project, hideImage = false }) {
  const { user } = useAuth();
  if (!project) {
    return <></>;
  }
  return (
    <Box padding={2}>
      <Grid container spacing={3}>
        {!hideImage && (
          <Grid item lg={5} md={5} sm={0} xs={0}>
            <Box sx={{ display: { lg: 'block', md: 'block', xs: 'none' } }}>
              <img
                src={`${process.env.REACT_APP_SPACES_ENDPOINT_POSTER_IMAGES}${project?.posterImage}`}
                alt={project?.title}
                width={'100%'}
              />
            </Box>
          </Grid>
        )}
        <Grid
          item
          lg={hideImage ? 12 : 7}
          md={hideImage ? 12 : 7}
          sm={hideImage ? 12 : 7}
          xs={hideImage ? 12 : 7}
        >
          <Box height="auto" marginLeft={hideImage ? 0 : 2}>
            <Box display="flex" flexDirection={'column'} margin={2} sx={{}}>
              <Box display={'block'} gap={1}>
                <Typography
                  variant="body1"
                  fontFamily="serif"
                  fontWeight={'fontWeightBold'}
                  color="black"
                  textOverflow={'ellipsis'}
                >
                  {project?.title.slice(0, 100)}
                  {project?.title.length > 100 && '...'}
                </Typography>
              </Box>
              <Box display="flex" flexDirection="row" flexWrap={true}>
                <Typography
                  variant="subtitle2"
                  fontFamily="sans-serif"
                  whiteSpace={'preserve'}
                  color="gray"
                >
                  |
                </Typography>
                {project?.ownersDescription.map((owner, index) => (
                  <div key={index}>
                    <Typography
                      variant="subtitle2"
                      fontFamily="sans-serif"
                      whiteSpace={'preserve'}
                      color="gray"
                    >
                      {`  ${owner?.fullName}  |`}
                    </Typography>
                  </div>
                ))}
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                flexWrap={true}
                gap={0.5}
                marginY={1.5}
              >
                <Chip
                  label={project?.course}
                  color="default"
                  variant="outlined"
                />
                <Chip
                  label={project?.department}
                  color="default"
                  variant="outlined"
                />
                <Chip
                  label={project?.year}
                  color="default"
                  variant="outlined"
                />
                |
                <Chip
                  label={'Abstract'}
                  color={project?.abstract ? 'success' : 'error'}
                  variant="outlined"
                />
                <Chip
                  label={'Poster Link'}
                  color={project?.posterLink ? 'success' : 'error'}
                  variant="outlined"
                />
                {project?.projectBids?.includes(user?._id) && (
                  <Chip
                    label={'Bid'}
                    icon={<Check />}
                    color={'success'}
                    variant="outlined"
                  />
                )}
              </Box>
              <Box display="flex" marginY={1}>
                <QuestionAnswer />
                <Typography
                  variant="subtitle2"
                  fontFamily={'sans-serif'}
                  color="gray"
                  marginLeft={2}
                >
                  {project?.abstract?.slice(0, 250) ||
                    project?.description?.slice(0, 250) ||
                    'No Abstract or Description'}
                  {project?.abstract?.length > 250 && '...'}
                  {!project?.abstract &&
                    project?.description?.length > 250 &&
                    '...'}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Divider sx={{ margin: 1 }} />
    </Box>
  );
}
