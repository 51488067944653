import { QuestionAnswer } from '@mui/icons-material';
import { Box, Chip, Divider, Grid, Typography } from '@mui/material';

export default function SearchRubric({ rubric, expandDescription = false }) {
  if (!rubric) {
    return <></>;
  }
  return (
    <Box padding={2}>
      <Grid container spacing={3}>
        <Grid item lg={7} md={7} sm={12} xs={12}>
          <Box height="auto" marginLeft={2}>
            <Box display="flex" flexDirection={'column'} margin={2} sx={{}}>
              <Box display={'block'} gap={1}>
                <Typography
                  variant="body1"
                  fontFamily="sans-serif"
                  fontWeight={'fontWeightBold'}
                  textOverflow={'ellipsis'}
                >
                  {rubric?.title.slice(0, 100)}
                  {rubric?.title.length > 100 && '...'}
                </Typography>
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                flexWrap={true}
                gap={1}
                marginY={1}
              >
                <Chip
                  label={rubric?.course}
                  color="default"
                  variant="outlined"
                />
                <Chip
                  label={rubric?.department}
                  color="default"
                  variant="outlined"
                />
                <Chip label={rubric?.type} color="default" variant="outlined" />
              </Box>
              <Box display="flex" marginY={1}>
                <QuestionAnswer />
                <Typography
                  variant="subtitle2"
                  fontFamily="sans-serif"
                  marginLeft={2}
                >
                  {expandDescription ? (
                    rubric?.description
                  ) : (
                    <>
                      {rubric?.description?.slice(0, 250) || 'No Description'}
                      {rubric?.description?.length > 250 && '...'}
                    </>
                  )}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Divider sx={{ margin: 1 }} />
    </Box>
  );
}
