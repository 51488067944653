import {
  Alert,
  Box,
  Button,
  Container,
  Fab,
  Grid,
  Paper,
  Toolbar,
  Tooltip,
} from '@mui/material';
import Dashboard from '../../../../components/Admin/Dashboard';
import { Styles } from '../../../../styles/global';
import { useEffect, useRef, useState } from 'react';
import {
  getRubrics,
  createRubric,
  objectToQueryString,
} from '../../../../utils/API';
import { useSearchParams } from 'react-router-dom';
import Filters from '../../../../components/Filters';
import rubricFiltersSchema from '../../../../utils/FiltersSchema/AdminRubrics';
import ProjectsView from '../../../../components/Views';
import { AddCard } from '@mui/icons-material';
import AdvancedModal from '../../../../components/AdvancedModal';
import RubricForm from '../../../../components/Rubrics';
import { useFeedback } from '../../../../utils/FeedbackContext';

function FiltersSidebar() {
  const [rubric, setRubric] = useState({
    title: '',
    description: '',
    type: '',
    department: '',
    course: '',
    numOfScoreCategories: 4,
    numOfRubricCategories: 4,
    weights: [1, 1, 1, 1],
    rubricCategories: ['', '', '', ''],
    scoreCategories: ['', '', '', ''],
    scoreCategoriesDescriptions: [
      ['', '', '', ''],
      ['', '', '', ''],
      ['', '', '', ''],
      ['', '', '', ''],
    ],
  });
  const [open, setOpen] = useState(false);
  const [error, setError] = useState();
  const [searchParams] = useSearchParams();
  const { notifySuccess, notifyError } = useFeedback();
  const handleSave = async () => {
    try {
      let params = {};
      for (const [key, value] of searchParams.entries()) {
        params[key] = value;
      }

      // convert rubric.weights to a number
      rubric.weights = rubric.weights.map((weight) => Number(weight));

      await createRubric({ ...params, ...rubric });
      notifySuccess('Rubric created successfully');
      setOpen(false);
    } catch (error) {
      notifyError(
        'Failed to create rubric, check error message for more details'
      );
      setError(error?.response?.data);
    }
  };

  return (
    <Box {...Styles.dashboardContentWidget('auto')}>
      <Filters filtersSchema={rubricFiltersSchema()} />
      <br />
      <Box textAlign="center">
        <Tooltip title="Create Rubric" aria-label="Create Rubric">
          <Button
            variant="contained"
            color="primary"
            aria-label="add"
            onClick={() => setOpen(true)}
          >
            <Box display="flex" gap={1}>
              <AddCard />
              Rubric
            </Box>
          </Button>
        </Tooltip>
        <AdvancedModal
          open={open}
          handleClose={() => setOpen(false)}
          handleSave={handleSave}
          title="Create a Rubric"
          width={'80vw'}
        >
          <RubricForm rubric={rubric} setRubric={setRubric} />
          {error && (
            <Alert severity="error" sx={{ marginY: 1 }}>
              {error?.errMessage}
            </Alert>
          )}
        </AdvancedModal>
      </Box>
    </Box>
  );
}

function Rubrics() {
  const [rubrics, setRubrics] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const topRef = useRef(null); // Create a ref for the top of the page
  const acceptableParams = ['department', 'course', 'type', 'limit', 'page'];
  useEffect(() => {
    if (!searchParams.get('limit')) {
      setSearchParams(`${searchParams.toString()}&limit=10`);
    }
    if (!searchParams.get('page')) {
      setSearchParams(`${searchParams.toString()}&page=1`);
    }
  }, [searchParams, setSearchParams]);

  useEffect(() => {
    async function fetchRubrics() {
      try {
        const params = {};
        for (const [key, value] of searchParams.entries()) {
          if (acceptableParams.includes(key)) {
            params[key] = value;
          }
        }
        const rubricsResponse = await getRubrics(objectToQueryString(params));
        setRubrics(rubricsResponse?.data || []);
      } catch (error) {
        console.log(error);
      }
    }
    try {
      fetchRubrics();
    } catch (error) {
      console.log(error);
    }
  }, [searchParams]);

  return (
    <>
      <Box {...Styles.mainBox}>
        <Toolbar />

        {/* Container With Max Width = lg breakpoint */}
        <Container {...Styles.customContainer('xl')}>
          {/*Outer Grid Container */}
          <Grid container spacing={3}>
            {/*Inner Grid Begins*/}

            {/*Inner Grid Item 0: Space Taken */}
            <Grid item lg={3} sm={0} xs={0} ref={topRef}>
              <div />
            </Grid>

            {/*Inner Grid Item 1: Page Title */}
            <Grid item lg={9} sm={12} xs={12} ref={topRef}>
              <Paper {...Styles.dashboardPageWidget()}>Rubrics</Paper>
            </Grid>

            {/*Inner Grid Item 2: Filters */}
            <Grid item lg={3} sm={12} xs={12}>
              <FiltersSidebar />
            </Grid>

            {/*Inner Grid Item 3: Projects View */}
            <Grid item lg={9} sm={12} xs={12}>
              <Paper {...Styles.dashboardContentMinWidget()}>
                <ProjectsView
                  items={rubrics}
                  topRef={topRef}
                  link="/admin/dashboard/rubrics"
                  itemType={'rubrics'}
                />
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}

export default function FacultyDashboardRubrics() {
  return <Dashboard Page={Rubrics} PageName="(A) Dashboard - Rubrics" />;
}
