import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../utils/AuthContext.js';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
  mainListItems,
  secondaryListItems,
} from '../../assets/facultyListItems.js';
import { getAvailableYears, objectToQueryString } from '../../utils/API.js';
import UserProfile from '../UserProfile.js';

function getColorForRole(role) {
  const colors = {
    ADMIN: '#1F6CFA',
    STUDENT: '#085C25',
    JUDGE: '#673AB7',
    FACULTY: '#1F6CFA',
  };
  return colors[role] || 'red'; // Default color if the role is not found
}

const drawerWidth = 240;

function YearsMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { user, isLoggedIn } = useAuth();
  const [years, setYears] = React.useState([new Date().getFullYear()]);
  const navigate = useNavigate();

  const open = Boolean(anchorEl);
  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [searchParams] = useSearchParams();

  const handleCloseYears = (year) => {
    const params = { year: year };
    if (searchParams.get('department'))
      params.department = searchParams.get('department');
    if (searchParams.get('course')) params.course = searchParams.get('course');
    navigate(`?${objectToQueryString(params)}`);
    setAnchorEl(null);
  };

  React.useEffect(() => {
    const fetchYears = async () => {
      try {
        const availableYears = await getAvailableYears(
          searchParams?.get('department')
        );
        setYears(
          availableYears?.data?.data?.sort(function (a, b) {
            return b - a;
          }) || years
        );
      } catch (error) {
        console.log(error);
      }
    };
    fetchYears();
  }, []);

  const buttonOptions = {
    my: 2,
    color: 'inherit',
    display: 'block',
    fontSize: '17px',
    textTransform: 'none',
  };

  return (
    isLoggedIn && (
      <div>
        <Button
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onMouseEnter={handleOpen}
          sx={{
            ...buttonOptions,
            display: 'flex',
            justifyContent: 'space-between', // Align text and icon
            alignItems: 'center', // Center vertically
            color: 'black',
            bgcolor: 'lightblue',
            ml: 2,
            borderRadius: 2,
            cursor: 'pointer',
          }}
          endIcon={<ExpandMoreIcon />}
        >
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1 }}
          >
            Year
          </Typography>
        </Button>

        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          disableScrollLock={true}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
            onMouseLeave: handleClose,
            color: 'white',
          }}
          PaperProps={{
            sx: {
              backgroundColor: '#white',
              transition: 'background-color 0.2s',
              transitionDelay: '0.1s',
              paddingX: 1,
              marginTop: 0.5,
              borderRadius: 2,
            },
          }}
        >
          {isLoggedIn &&
            years &&
            years?.map((year) => (
              <MenuItem
                key={year}
                sx={{
                  color: 'black',
                }}
                onClick={() => handleCloseYears(year)}
                divider
                selected={searchParams.get('year') === year.toString()}
              >
                {year}
              </MenuItem>
            ))}
        </Menu>
      </div>
    )
  );
}

function CourseMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { user, isLoggedIn } = useAuth();
  const navigate = useNavigate();

  const courses = ['SD1', 'SD2'];

  const open = Boolean(anchorEl);
  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [searchParams] = useSearchParams();

  const handleCloseCourses = (course) => {
    const params = { course: course };
    if (searchParams.get('department'))
      params.department = searchParams.get('department');
    if (searchParams.get('year')) params.year = searchParams.get('year');
    navigate(`?${objectToQueryString(params)}`);

    setAnchorEl(null);
  };

  const buttonOptions = {
    my: 2,
    color: 'inherit',
    display: 'block',
    fontSize: '17px',
    textTransform: 'none',
  };

  return (
    isLoggedIn && (
      <div>
        <Button
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onMouseEnter={handleOpen}
          sx={{
            ...buttonOptions,
            display: 'flex',
            justifyContent: 'space-between', // Align text and icon
            alignItems: 'center', // Center vertically
            color: 'black',
            bgcolor: 'lightblue',
            ml: 2,
            borderRadius: 2,
          }}
          endIcon={<ExpandMoreIcon />}
        >
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1 }}
          >
            Course
          </Typography>
        </Button>

        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          disableScrollLock={true}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
            onMouseLeave: handleClose,
            color: 'white',
          }}
          PaperProps={{
            sx: {
              backgroundColor: '#white',
              transition: 'background-color 0.2s',
              transitionDelay: '0.1s',
              paddingX: 2,
              marginTop: 0.5,
              borderRadius: 2,
              marginLeft: 2,
            },
          }}
        >
          {isLoggedIn &&
            courses &&
            courses?.map((course) => (
              <MenuItem
                key={course}
                sx={{
                  color: 'black',
                }}
                onClick={() => handleCloseCourses(course)}
                divider
                selected={searchParams.get('course') === course}
              >
                {course}
              </MenuItem>
            ))}
        </Menu>
      </div>
    )
  );
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function Dashboard({ Page, PageName }) {
  const [open, setOpen] = React.useState(true);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [searchParams] = useSearchParams();
  const { pid } = useParams();
  const [profileOpen, setProfileOpen] = useState(false);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = (event) => {
    setAnchorElUser(null);
  };

  const { user, logout, isLoggedIn } = useAuth();
  const navigate = useNavigate();

  const Icon = user?.icon ? (
    <Avatar
      alt={user.fullName}
      src={`${process.env.REACT_APP_SPACES_ENDPOINT_PROFILE_ICONS}${user?.icon}`}
    />
  ) : (
    <Avatar
      sx={{
        bgcolor: getColorForRole(user.role),
        fontSize: '18px',
      }}
    >
      {(user.firstName[0] + user.lastName[0]).toUpperCase()}{' '}
    </Avatar>
  );

  const settings = [
    {
      title: 'Profile',
      feature: function () {
        setProfileOpen(true);
      },
    },
    {
      title: 'Dashboard',
      feature: function () {
        if (user?.role === 'FACULTY') {
          navigate(
            `/faculty/dashboard?department=${user?.department}&year=${user?.year}&course=SD2`
          );
          return;
        }
        if (user?.role === 'ADMIN') {
          navigate(`/admin/dashboard?year=${new Date().getFullYear()}`);
          return;
        }
      },
    },
    {
      title: 'Logout',
      feature: function () {
        logout();
        navigate('/portal/faculty');
      },
    },
  ];

  const toggleDrawer = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/');
    }
  }, [isLoggedIn, navigate, user]);

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar
          position="absolute"
          open={open}
          sx={{
            bgcolor: '#10284B',
            color: '#FFDB53',
          }}
        >
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
              overflowX: 'auto',
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>

            <YearsMenu />

            <CourseMenu />

            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1, marginLeft: 2 }}
            >
              <Box
                p={1}
                width="fit-content"
                border={1}
                borderRadius={2}
                fontFamily={'sans-serif'}
              >
                {PageName || 'Dashboard'}
              </Box>
            </Typography>

            <IconButton color="inherit" sx={{ mr: 2 }}>
              <Badge badgeContent={0} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>

            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title={isLoggedIn && 'Open settings'}>
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  {isLoggedIn ? Icon : <Avatar src="/broken-image.jpg" />}
                </IconButton>
              </Tooltip>

              {isLoggedIn && (
                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbars"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  MenuListProps={{ disablePadding: true }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                  disableScrollLock={true}
                >
                  {settings.map((setting) => (
                    <MenuItem
                      key={setting.title}
                      onClick={handleCloseUserMenu && setting.feature}
                    >
                      {setting.title}
                    </MenuItem>
                  ))}
                </Menu>
              )}
            </Box>
          </Toolbar>
        </AppBar>

        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            {mainListItems(searchParams.toString())}
            <Divider sx={{ my: 1 }} />
            {secondaryListItems(pid)}
          </List>
        </Drawer>

        <Page />

        <UserProfile
          open={profileOpen}
          onClose={() => setProfileOpen(false)}
          title="User Profile"
          user={user}
        />
      </Box>
    </ThemeProvider>
  );
}
