import { QuestionAnswer } from '@mui/icons-material';
import { Box, Chip, Divider, Grid, Typography } from '@mui/material';

export default function SearchTutorial({
  tutorial,
  expandDescription = false,
}) {
  if (!tutorial) {
    return <></>;
  }
  return (
    <Box padding={2}>
      <Grid container spacing={3}>
        <Grid item lg={7} md={7} sm={12} xs={12}>
          <Box height="auto" marginLeft={2}>
            <Box display="flex" flexDirection={'column'} margin={2} sx={{}}>
              <Box display={'block'} gap={1}>
                <Typography
                  variant="body1"
                  fontWeight={'fontWeightBold'}
                  fontFamily="sans-serif"
                  textOverflow={'ellipsis'}
                >
                  {tutorial?.title.slice(0, 100)}
                  {tutorial?.title.length > 100 && '...'}
                </Typography>
              </Box>
              <Box display="flex" flexDirection="row" flexWrap={true}>
                <Typography
                  variant="subtitle2"
                  fontFamily="sans-serif"
                  whiteSpace={'preserve'}
                  color="gray"
                >
                  {`|  ${tutorial?.instructor}  |`}
                </Typography>
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                flexWrap={true}
                gap={1}
                marginY={1}
              >
                <Chip
                  label={tutorial?.course}
                  color="default"
                  variant="outlined"
                />
                <Chip
                  label={tutorial?.department}
                  color="default"
                  variant="outlined"
                />
                <Chip
                  label={tutorial?.year}
                  color="default"
                  variant="outlined"
                />
                |
                <Chip
                  label={tutorial?.published ? 'Published' : 'Unpublished'}
                  color={tutorial?.published ? 'success' : 'error'}
                  variant="outlined"
                />
              </Box>
              <Box display="flex" marginY={1}>
                <QuestionAnswer />
                <Typography
                  variant="subtitle2"
                  fontFamily="sans-serif"
                  marginLeft={2}
                  color="gray"
                >
                  {expandDescription ? (
                    tutorial?.description
                  ) : (
                    <>
                      {tutorial?.description?.slice(0, 250) || 'No Description'}
                      {tutorial?.description?.length > 250 && '...'}
                    </>
                  )}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Divider sx={{ margin: 1 }} />
    </Box>
  );
}
