import React from 'react';
import { IconButton } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const Clipboard = ({ onSuccess, onError, text }) => {
  const handleCopy = () => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        if (!!onSuccess) {
          onSuccess();
        }
      })
      .catch((err) => {
        console.error('Failed to copy text:', err);
        if (!!onError) {
          onError();
        }
      });
  };

  return (
    <IconButton color="primary" onClick={handleCopy}>
      <ContentCopyIcon />
    </IconButton>
  );
};

export default Clipboard;
